<template>
  <v-container class="payment-completed" v-if="order">
    <PaymentStepper :step="3" />
    <v-row no-gutters class="mt-7">
      <v-col cols="12" sm="7" class="order-info">
        <v-card class="text-left default--text h-100" elevation="0">
          <CategoryTitle
            :category="category"
            v-if="
              order.orderStatusId !== 11 &&
                order.orderStatusId != 2 &&
                order.orderStatusId != 1 &&
                !paymentFailed
            "
          />
          <div class="d-flex align-center flex-row flex-wrap">
            <div
              class="text-h4 font-weight-bold text-uppercase default--text"
              v-if="paymentFailed"
            >
              {{ $t("paymentCompleted.failed") }}
            </div>
          </div>
          <v-card-text v-if="paymentFailed" class="py-0">
            <div class="text-h5" v-html="$t('paymentCompleted.failedDesc1')" />
            <h1
              color="primary"
              class="font-weight-bold my-7 text-decoration-none text-center text-sm-left"
            >
              <router-link
                :to="{ name: 'Order', params: { orderId: order.orderId } }"
                >{{ `N. ${order.orderId}` }}</router-link
              >
            </h1>
            <div class="text-h5" v-html="$t('paymentCompleted.failedDesc2')" />
          </v-card-text>

          <v-card-text v-if="!paymentFailed">
            <div
              class="text-h4 font-weight-bold text-uppercase default--text"
              v-html="$t('paymentCompleted.success')"
            />
            <div class="text-h5" v-html="$t('paymentCompleted.successDesc')" />
            <h1
              color="primary"
              class="font-weight-bold my-7 mb-sm-0 text-decoration-none text-center text-sm-left"
            >
              <router-link
                :to="{ name: 'Order', params: { orderId: order.orderId } }"
                >{{ `N. ${order.orderId}` }}</router-link
              >
            </h1>
          </v-card-text>

          <v-card-text v-if="!paymentFailed">
            <template>
              <i18n path="paymentCompleted.reminder" tag="div" class="text-h5">
                <template v-slot:link>
                  <router-link
                    class="text-decoration-none"
                    :to="{
                      name: 'Order',
                      params: { orderId: order.orderId }
                    }"
                  >
                    <span class="font-weight-bold default--text">
                      {{ $t("paymentCompleted.ordersLink") }}</span
                    >
                  </router-link>
                </template>
              </i18n>
            </template>

            <div
              class="text-body-2 default--text mt-4 py-6 px-4 primary lighten-2 rounded-sm"
            >
              <div class="font-weight-bold">
                {{ $t("paymentCompleted.detail.title") }}
              </div>
              <i18n
                :path="
                  `paymentCompleted.detail.${order.shippingAddress.deliveryServiceId}`
                "
                tag="div"
              >
                <template v-slot:1>
                  <span class="font-weight-bold default--text">
                    {{ detailDate }}
                  </span>
                </template>
                <template v-slot:2>
                  <span class="font-weight-bold default--text">
                    {{ detailTimeslot }}
                  </span>
                </template>
                <template v-slot:3>
                  <span class="font-weight-bold default--text">
                    {{ order.shippingAddress.addressName }}
                  </span>
                </template>
              </i18n>
            </div>
          </v-card-text>
          <div v-if="showPayment">
            <PaymentTypeList
              mode="order"
              :order-id="order.orderId"
              :options="paymentTypeListOptions"
            />
          </div>
          <v-card-actions class="pl-0 pr-3 pb-5 pt-5">
            <v-row no-gutters justify="space-between" align="center">
              <v-col cols="12" sm="4">
                <!-- <v-btn
                  text
                  class="primary--text px-sm-0 mb-4 mb-sm-0"
                  v-bind:to="'/'"
                  large
                  :outlined="$vuetify.breakpoint.xs"
                  :block="$vuetify.breakpoint.xs"
                >
                  <v-icon color="primary" class="d-none d-sm-flex"
                    >$prev</v-icon
                  >
                  {{ $t("paymentCompleted.goToHome") }}
                </v-btn> -->
                <back-button
                  :label="$t('paymentCompleted.goToHome')"
                  @back="goHome"
                />
              </v-col>
              <v-col
                cols="12"
                sm="auto"
                v-if="paymentFailed && order.isPayable"
                class="mb-4 mb-sm-0"
              >
                <v-btn
                  v-if="!showPayment"
                  color="primary"
                  large
                  :block="$vuetify.breakpoint.xs"
                  @click="toggleShowPayment"
                >
                  {{ $t("paymentCompleted.retry") }}
                </v-btn>
              </v-col>

              <v-col
                v-if="!paymentFailed"
                cols="12"
                sm="auto"
                class="mb-4 mb-sm-0"
              >
                <v-btn
                  v-bind:to="'/profile/orders/' + order.orderId"
                  color="primary"
                  large
                  :block="$vuetify.breakpoint.xs"
                >
                  {{ $t("paymentCompleted.goToDetail") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" sm="5" class="shipping-instruction-wrapper">
        <img :src="shippingIstructionImg" alt="Istruzioni per il ritiro" />
        <!-- <v-card
          elevation="0"
          class="sideBox py-7 px-6 white--text primary lighten-1 h-100 text-center text-sm-left"
        >
          <h3 class="font-weight-bold">
            {{ $t("paymentCompleted.sideBox.title") }}
          </h3>
          <h5 class="font-weight-bold">
            {{ $t("paymentCompleted.sideBox.description") }}
          </h5>
          <v-btn
            large
            min-width="160"
            outlined
            color="primary"
            class="white mt-4"
            to="/page/ritiro-in-negozio"
            >{{ $t("paymentCompleted.sideBox.button") }}</v-btn
          >
        </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>
<style scoped lang="scss">
.text-decoration-none {
  a {
    text-decoration: none !important;
  }
}
.payment-type-list {
  font-size: 20px !important;
}
.shipping-instruction-wrapper {
  text-align: center;
  img {
    max-width: 100%;
  }
}
.text-h5 {
  line-height: 24px;
}
.sideBox {
  background-image: url("/img_layout/payment-completed.png");
  background-position: bottom;
  background-size: contain;
}
.v-card__text {
  padding-left: 0;
}

@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .v-card__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  .order-info,
  .sideBox {
    min-height: 520px;
  }
  .payment-methods {
    margin-bottom: 50px;
  }
}
</style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import PaymentStepper from "@/components/payment/PaymentStepper.vue";
import PaymentTypeList from "~/components/payment/PaymentTypeList.vue";

import categoryMixins from "~/mixins/category";

import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";

import { mapActions, mapGetters } from "vuex";
import BackButton from "@/components/navigation/BackButton.vue";

export default {
  name: "PaymentCompleted",
  mixins: [categoryMixins],
  components: { CategoryTitle, PaymentStepper, PaymentTypeList, BackButton },
  data() {
    return {
      order: null,
      showPayment: false,
      paymentTypeListOptions: {
        flatPanels: true,
        showCardLogos: true,
        titleStyle: "default--text text-uppercase payment-type-list-title",
        prevButton: {
          type: "link-button",
          label: "payment.backToCheckout",
          class: "text-none d-none",
          options: {
            icon: "$prev",
            iconClass: "primary white--text rounded-lg mr-3",
            textClass: "primary--text text-caption font-weight-bold"
          }
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      isOrderSentToAnalytics: "app/isOrderSentToAnalytics"
    }),
    paymentFailed() {
      return (
        this.order.orderStatusId == 2 ||
        this.order.orderStatusId == 11 ||
        this.order.orderStatusId == 1
      );
    },
    detailDate() {
      return this.$dayjs(this.order.timeslot.date).format("D MMMM YYYY");
    },
    detailTimeslot() {
      return `dalle ${this.$dayjs(this.order.timeslot.beginTime, [
        "h:mm:ss A",
        "H:mm"
      ]).format("HH:mm")} alle
        ${this.$dayjs(this.order.timeslot.endTime, [
          "h:mm:ss A",
          "H:mm"
        ]).format("HH:mm")}`;
    },
    shippingIstructionImg() {
      return `/pdv/consegna/${this.order.shippingAddress.addressId}.jpg`;
    }
  },
  methods: {
    ...mapActions({
      addOrderToAnalytics: "app/addOrderSentToAnalytics"
    }),
    async fetchOrder(orderId) {
      let _this = this;
      _this.order = await OrderService.getOrder(orderId);
      if (_this.order.orderStatusId == 3) {
        if (!this.isOrderSentToAnalytics(orderId)) {
          try {
            AnalyticsService.purchase(_this.order);
            this.addOrderToAnalytics(orderId);
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    toggleShowPayment() {
      this.showPayment = !this.showPayment;
    },
    goHome() {
      this.$router.push({ name: "Home" });
    }
  },
  mounted() {
    this.fetchOrder(this.$route.params.orderId);
    global.EventBus.$on("pay-response-ok", order => {
      this.showPayment = false;
      this.fetchOrder(order.orderId);
    });
  },
  beforeDestroy() {
    global.EventBus.$off("pay-response-ok");
  }
};
</script>
